@media (min-width:768px){
    .navbar > .container .navbar-brand,
    .navbar > .container-fluid .navbar-brand {
      margin-left: 15px;
    }
  }

footer {
  margin-top: 2em;
}

.form-register-card {
    padding: 30px 70px;
    box-shadow: 0 10px 30px 0 rgb(55 97 98 / 15%);
}
.form-register-card.card {
    border-radius: 0;
    border: 0;
}

.form-select-card {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;

  box-shadow: 0 10px 30px 0 rgb(55 97 98 / 15%);
  width: 100%;
}
.form-select-card.card {
  border-radius: 0;
  border: 0;

  transition: transform 0.2s ease;
}
.form-select-card.card:hover {
  transform: scale(1.1);
}

.form-select-card-icon {
  font-size: 50px;
  color: #264159;
}

label {
  font-weight: bold;
}

h1, h2, h3, h4, h5, h6, label, a, .btn-link,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: #264159;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: Ubuntu, "times new roman", times, roman, serif;
  font-weight: bold;
}
html, body, .tooltip, .popover {
  font-family: Ubuntu, "times new roman", times, roman, serif;
  color: #585858;
}
code, kbd, pre, samp, .text-monospace {
  font-family: Ubuntu, "times new roman", times, roman, serif;
}
button, input, optgroup, select, textarea {
  font-family: inherit
}

button.form-button, a.form-button {
  border-radius: 5px !important;
  background-color: #264159 !important;
  border: solid 1px #264159 !important;
  color: white !important;
}
button:hover.form-button, a:hover.form-button {
  background-color: white !important;
  color: #585858 !important;
}