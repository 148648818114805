@media (min-width:768px) {

  .navbar>.container .navbar-brand,
  .navbar>.container-fluid .navbar-brand {
    margin-left: 15px;
  }
}

footer {
  margin-top: 2em;
}

.form-register-card {
  padding: 30px 70px;
  box-shadow: 0 10px 30px 0 rgb(55 97 98 / 15%);
}

.form-register-card.card {
  border-radius: 0;
  border: 0;
}

.form-select-card {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;

  box-shadow: 0 10px 30px 0 rgb(55 97 98 / 15%);
  width: 100%;
}

.form-select-card.card {
  border-radius: 0;
  border: 0;

  transition: transform 0.2s ease;
}

.form-select-card.card:hover {
  transform: scale(1.1);
}

.form-select-card-icon {
  font-size: 50px;
  color: #264159;
}

label {
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
a,
.btn-link,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #264159;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: Ubuntu, "times new roman", times, roman, serif;
  font-weight: bold;
}

html,
body,
.tooltip,
.popover {
  font-family: Ubuntu, "times new roman", times, roman, serif;
  color: #585858;
}

code,
kbd,
pre,
samp,
.text-monospace {
  font-family: Ubuntu, "times new roman", times, roman, serif;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit
}

button.form-button,
a.form-button {
  border-radius: 5px !important;
  background-color: #264159 !important;
  border: solid 1px #264159 !important;
  color: white !important;
}

button:hover.form-button,
a:hover.form-button {
  background-color: white !important;
  color: #585858 !important;
}

button.form-button.btn-danger,
a.form-button.btn-danger {
  border-radius: 5px !important;
  background-color: #dc3545 !important;
  border: solid 1px #dc3545 !important;
  color: white !important;
}

button:hover.form-button.btn-danger,
a:hover.form-button.btn-danger {
  background-color: white !important;
  color: #585858 !important;
}

.floating-box {
  position: fixed;
  width: 500px;
  height: 200px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  bottom: 25px;
  left: 25px;
  z-index: 1000;
  padding: 10px;
  cursor: move;
  display: flex;
  flex-direction: column;
}

.floating-header {
  background: rgb(0 17 35 / 57%);
  color: white;
  padding: 5px;
  cursor: grab;
  border-radius: 8px 8px 8px 8px;
  text-align: center;
  font-weight: bold;
}

.floating-textarea {
  flex-grow: 1;
  width: 100%;
  border: none;
  outline: none;
  resize: none;
}

.resizer {
  width: 18px;
  height: 18px;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: nwse-resize;

  display: flex;
  align-items: end;
  justify-content: end;
  padding-right: 2px;
  padding-bottom: 2px;
}