// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;


$primary: #e280d6;
$secondary: #71ebf0;

/* $theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary
); */